import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ContactLayout from "../components/ui/layout/ContactLayout";
import sec5logo from "../assets/images/hero_bg.jpg";
import { CiLocationOn } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Image from "../assets/images/android-chrome-512x512.png";
import { Spin } from "antd";


const PreConstruct = () => {
    const [cardData, setCardData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://www.luxlifemiamirealestate.com/api/api/get/PreConstruction");
                if (response.ok) {
                    const data = await response.json();
                    if (data.data && data.data.length > 0) {
                        const parsedData = data.data.map(item => ({
                            ...item,
                            photos: JSON.parse(item.photos),
                        }));
                        setCardData(parsedData);
                        setLoading(false);
                    }
                } else {
                    console.error("Error fetching data");
                    setLoading(false);
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

  
    const handleNavigate = (data) => {
        navigate(`/pre-construction/${data.link}`);
    };



    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    const totalPages = Math.ceil(cardData.length / postsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (loading) return <Spin spinning={true} className="h-[100vh] w-[100vw] flex justify-center items-center"></Spin>;
    if (error) return <div>{error}</div>;

    const currentPosts = cardData.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
    );



    return (
        <ContactLayout>
            <Helmet>
                <title>Luxlife New Development in Miami - Luxlife Miami Real Estate LLC</title>
            </Helmet>

            <div className="relative w-full max-h-[500px] md:max-h-[600px] min-h-[350px] lg:max-h-[700px]">
                <img
                    src={sec5logo}
                    alt="Hero Image"
                    className="w-full h-full max-h-[500px] md:max-h-[600px] min-h-[350px] lg:max-h-[700px] object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute top-[50%] left-6 transform -translate-y-1/2 text-white px-4 md:px-8 w-full">
                    <p className="text-[3em] creato-f-l mt-6">Luxlife.</p>
                    <h2 className="text-[30px] md:text-[48px] lg:text-[3.2vw] creato-f-black tracking-wide mx-auto">
                        New Developments
                    </h2>
                    <p className="text-[18px] md:text-[20px] lg:text-[23px] w-[80vw] creato-f-r mt-2 max-w-[500px]">
                        Discover all of Miami's new preconstruction condo developments.
                    </p>
                    <div className="flex mt-14 mb-10">
                        <a href="#new-development">
                            <Button
                                title={
                                    <div className="flex items-center gap-4">
                                        <span>View more</span>
                                        <span>
                                            <IoIosArrowForward className="text-[20px]" />
                                        </span>
                                    </div>
                                }
                                className="bg-theme_yellow px-8 text-white rounded-full py-3 creato-f-m tracking-wide btn2"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className="container lg:max-w-[80%] mx-auto px-4" id="new-development">
                <div className="flex justify-center text-center md:text-start lg:justify-between flex-col lg:flex-row items-center lg:items-baseline mt-16 mb-8">
                    <h1 className="creato-f-black text-theme_blue text-[30px] leading-[30px] md:text-[3vw] md:leading-[3vw]">
                        Next-Gen <br />
                        New Developments
                    </h1>
                    <p className="max-w-[600px] text-[18px] creato-f-l lg:text-end md:text-[30px] text-wrap break-words mt-4 lg:mt-0">
                        Explore transformative new buildings that redefine modern luxury living.
                    </p>
                </div>

                {/* <InfiniteScroll
                    dataLength={displayData.length}
                    next={loadMoreData}
                    hasMore={hasMore}
                    loader={<h4 className="w-100 text-center">Loading...</h4>}
                    scrollThreshold={0.55} // Triggers load when 80% down the page
                > */}
         
                    <div className="flex flex-wrap gap-4 lg:gap-6 justify-center py-8">
                        {currentPosts.map((card) => (
                            <div
                                onClick={() => handleNavigate(card)}
                                key={card.id}
                                className="relative dark-bottom-overlay overflow-hidden group cursor-pointer max-w-[460px] lg:w-[22vw] rounded-xl"
                            >
                                <LazyLoadImage
                                    alt={card.title}
                                    src={
                                        card?.photos &&
                                        card?.photos.length > 0 &&
                                        card?.photos[0] &&
                                        card?.photos[0].includes(
                                            "https://www.luxlifemiamirealestate.com/"
                                        )
                                            ? card?.photos[0]
                                            : `https://www.luxlifemiamirealestate.com/api/storage/app/public/${card?.photos[0]}`
                                    }
                                    PlaceholderSrc={Image}
                                    effect="opacity"
                                    className="group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect"
                                />
                                <div className="absolute z-30 bottom-4 w-full px-4 text-white">
                                    <p className="text-[22px] leading-[33px] poppins-semibold">{card.title}</p>
                                    <div className="p-2 flex gap-1 items-center">
                                        <CiLocationOn className="text-white text-[23px] transform transition-transform duration-700" />
                                        <p className="tracking-wider creato-f-l">{card?.address}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                {/* </InfiniteScroll> */}

                <div className="flex justify-center mt-8 mb-20">
                <div className="pagination-container">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-2 mr-2"
                    >
                        «
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-2 ${
                                currentPage === index + 1 ? 'bg-theme_yellow text-white' : ''
                            }`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-5 py-3 creato-f-r border border-gray-300 rounded-full ml-4"
                    >
                        »
                    </button>
                </div>
            </div>


            </div>
        </ContactLayout>
    );
};

export default PreConstruct;