import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/ui/header/Header';
import sec5logo from '../assets/images/singlePreConstruction.svg';
import Button from '../components/buttons/Button';
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io';
import { LuDownload } from 'react-icons/lu';
import { AiFillPicture } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import { BiChevronRight } from 'react-icons/bi';
import TypeTextAnimation from '../components/animation/TypeAnimationText';
import AnimationDiv, { slideBottom, slideLeft } from '../components/animation/AnimationDiv';
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs';
import Footer from '../components/ui/sections/FooterSection/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getActiveListingData, getListingData, getSingleBuilding } from '../api/GET';
import { Helmet } from 'react-helmet';
import { Image, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AgentCard from '../components/cards/AgentCard';
import Card from '../components/cards/Card';
import { API_BASE_URL } from '../config';
import axios from 'axios';

import SwimmingPool from "../assets/images/ammenities/Swimming-Pool.png";
import CarChangingStation from "../assets/images/ammenities/Car-Charging-Station.png";
import Bayfront from "../assets/images/ammenities/Bayfront.png";
import BeachClub from "../assets/images/ammenities/Beach-Club.png";
import Canabas from "../assets/images/ammenities/cabanas.png";
import CommonAreaWifi from "../assets/images/ammenities/Common-Area-Wifi.png";
import CoveredParking from "../assets/images/ammenities/Covered-Parking.png";
import FitnessCenter from "../assets/images/ammenities/Fitness-Center.png";
import GatedAccess from "../assets/images/ammenities/Gated-Access.png";
import OceanFront from "../assets/images/ammenities/Oceanfront.png";
import OutdoorKitchen from "../assets/images/ammenities/Outdoor-Kitchens.png";
import ParkingGarage from "../assets/images/ammenities/Parking-Garage.png";
import Restaurant from "../assets/images/ammenities/Restaurant.png";
import Sauna from "../assets/images/ammenities/Sauna.png";
import SreamRoom from "../assets/images/ammenities/Steam-Room.png";
import Tennis from "../assets/images/ammenities/Tennis.png";
import ValetParking from "../assets/images/ammenities/Valet-Parking.png";
import LuxlifeAmmenities from "../assets/images/ammenities/luxlife_ammenities.png";

import ImageGallery from 'react-image-gallery';

const amenityImages = {
    "swimming pool": SwimmingPool,
    "car changing station": CarChangingStation,
    "bayfront": Bayfront,
    "beach club": BeachClub,
    "canabas": Canabas,
    "common area wifi": CommonAreaWifi,
    "covered parking": CoveredParking,
    "fitness center": FitnessCenter,
    "gated access": GatedAccess,
    "oceanfront": OceanFront,
    "outdoor kitchens": OutdoorKitchen,
    "parking garage": ParkingGarage,
    "restaurant": Restaurant,
    "sauna": Sauna,
    "steam room": SreamRoom,
    "tennis": Tennis,
    "valet parking": ValetParking,
  };

const ResidenceTable = ({ title, data }) => (
    <div className="my-8 creato-f-b text-theme_blue">
        <h2 className="text-4xl creato-f-r mb-4">{title}</h2>
        <div className="w-full overflow-x-auto mt-10">
            <table className="w-full border-collapse overflow lg:text-[.9vw]">
                <thead>
                    <tr className="text-left text-gray-600 border-b">
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold min-w-[50px]">UNIT</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold max-w-[100px]">RESIDENCE</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold min-w-[50px]">BEDS</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold min-w-[50px]">BATHS</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold max-w-[100px]">LISTING DATE</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold max-w-[100px]">PRICE</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold min-w-[50px]">SQFT</th>
                        <th className="py-2 sm:lg:md:text-base text-sm font-semibold min-w-[50px]">STATUS</th>
                        <th className="py-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 && data.map((item) => (
                        <tr key={item.id} className="border-b last:border-b-0 hover:bg-gray-50 cursor-pointer">
                            <td className="py-4 min-w-[50px] pl-2">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    {item?.UnitNumber}
                                </Link>
                            </td>
                            <td className="py-4 pl-2 max-w-[100px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                {(item?.ListingInfo?.StreetAddress?.StreetNumber + ' ' + item?.ListingInfo?.StreetAddress?.StreetName + ' ' + item?.ListingInfo?.Location?.State)}
                                </Link>
                            </td>
                            <td className="py-4 min-w-[50px] pl-2">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    {item?.PropertyDetails?.Bedrooms}
                                </Link>
                            </td>
                            <td className="py-4 min-w-[50px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    {item?.PropertyDetails?.BathroomsTotal}
                                </Link>
                            </td>
                            <td className="py-4 max-w-[100px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    {item?.OnMarketDate}
                                </Link>
                            </td>
                            <td className="py-4 max-w-[100px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                $
                        {item?.ListingInfo?.PriceInfo?.ClosePrice ? String(Number(item?.ListingInfo?.PriceInfo?.ClosePrice).toLocaleString()) : item?.ListingInfo?.PriceInfo?.OriginalListPrice
                            ? String(Number(item?.ListingInfo?.PriceInfo?.OriginalListPrice).toLocaleString())
                            : item?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString()}
                                </Link>
                            </td>
                            <td className="py-4 min-w-[50px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    {item?.PropertyDetails?.LivingArea} sqft
                                </Link>
                            </td>
                            <td className="py-4 min-w-[50px]">
                                <Link className="creato-f-m sm:md:lg:text-sm text-xs" to={`/single-property/${item?.ListingID}`}>
                                    <span
                                        className={`px-2 py-1 text-sm font-semibold rounded ${item?.ListingInfo?.MLSDetails?.StandardStatus === 'Active'
                                                ? 'bg-theme_blue text-white'
                                                : 'bg-gray-300 text-white'
                                            }`}
                                    >
                                        {item?.ListingInfo?.MLSDetails?.StandardStatus}
                                    </span>
                                </Link>
                            </td>
                            <td className="py-4 ">
                                <Link to={`/single-property/${item?.ListingID}`}>
                                    <BiChevronRight className="text-gray-400" />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>

);

const SingleBuilding = () => {
    const { id } = useParams();
    const navigate = useNavigate();
// let amenities = ["Swimming Pool", "Car Changing Station", "Bayfront", "Beach Club", "Canabas", "Common Area Wifi", "Covered Parking", "Fitness Center", "Gated Access", "Oceanfront", "Outdoor Kitchens", "Parking Garage", "Restaurant", "Sauna", "Steam Room", "Swimming Pool", "Tennis", "Valet Parking"]
    const [data, setdata] = useState({})
    const [properties, setProperties] = useState([])
    const [ActiveProperties, setActiveProperties] = useState([])
    const [UnActiveProperties, setUnActiveProperties] = useState([])
    const [isPreviewVisible, setPreviewVisible] = useState(false);

    const [isStuck, setIsStuck] = useState(false);
    const overviewRef = useRef(null);

    const [activeLink, setActiveLink] = useState("#featured");

    useEffect(() => {
        const handleHashChange = () => {
            setActiveLink(window.location.hash !== '' ? window.location.hash : '#featured');
        };

        // Listen for hash changes
        window.addEventListener("hashchange", handleHashChange);
        
        // Check the initial hash on component mount
        handleHashChange();
        
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
        
        
    }, []);

    useEffect(()=>{

        window.addEventListener("scroll", fixedSidebar)
    },[])

function fixedSidebar(){
    if(window.scrollY >= 850 && window.scrollY <= 4450){
        setIsStuck(true)
    }else{
        setIsStuck(false)
    }
}


    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
        if (loading) return;

        setLoading(true);

        const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
        const headers = {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        };

        try {
            if (!isSaved) {
                setIsSaved(true); // Mark as saved
                // API call to add to saved
                await axios.post(
                    `${API_BASE_URL}domainparmeter/saved-property/store`,
                    { ListingID: ListingID },
                    { headers } // Pass headers with the request
                );
            } else {
                setIsSaved(false); // Mark as unsaved
                // API call to remove from saved
                await axios.delete(
                    `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                    { headers } // Pass headers with the request
                );
            }
        } catch (error) {
            console.error('Error toggling wishlist:', error);
        } finally {
            setLoading(false);
        }
    };
    function revertHyphenatedToOriginal(hyphenatedString) {
        return hyphenatedString
          .split("-") // Split the string by hyphens
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(" "); // Join the words back with spaces
      }

    const fetchData = async () => {
        
        const res = await getSingleBuilding(revertHyphenatedToOriginal(id))

        if (res?.data?.data && res?.data?.data.length > 0) {

            setdata(res?.data?.data[0])
        }
    }
   let newName = revertHyphenatedToOriginal(id).replace(/\b(East|West)\b/g, "").trim();
   const fetchProperties = async () => {
    const data = await getListingData(`order=desc&OriginalListPrice.gte=1000000&sortBy=OriginalListPrice&limit=8&BuildingName=${newName}`, true);
    if (Array.isArray(data?.data?.data)) {
        const nameWords = newName.split(" "); // Split newName into words

        const sortedListings = data?.data?.data.sort((a, b) => {
            const aExclusive = a.is_exclusive === 1 ? 1 : 0;
            const bExclusive = b.is_exclusive === 1 ? 1 : 0;

            // Prioritize by exclusivity, then by address matching
            if (aExclusive !== bExclusive) return bExclusive - aExclusive;

            // Both items have the same exclusivity; fallback to address matching
            const aAddressMatch = nameWords.some(word =>
                (a?.ListingInfo?.StreetAddress?.Address || "").toLowerCase().includes(word.toLowerCase())
            );
            const bAddressMatch = nameWords.some(word =>
                (b?.ListingInfo?.StreetAddress?.Address || "").toLowerCase().includes(word.toLowerCase())
            );

            return bAddressMatch - aAddressMatch;
        });

        setProperties(data?.data?.data.slice(0, 8));
    }
};


    const fetchActiveProperties = async () => {
        const data = await getActiveListingData(`limit=200&BuildingName=${newName}&StandardStatus=Active`);
        if (Array.isArray(data?.data?.data)) {
            const uniqueListings = data?.data?.data.reduce((acc, current) => {
                if (!acc.some(item => item.Listingkey === current.Listingkey)) {
                    acc.push(current);
                }
                return acc;
            }, []);
            setActiveProperties(uniqueListings)
        }
    }
    const fetchUnActiveProperties = async () => {
        const data = await getActiveListingData(`limit=200&BuildingName=${newName}&StandardStatus.ne=Active`);
        if (Array.isArray(data?.data?.data)) {
            const uniqueListings = data?.data?.data.reduce((acc, current) => {
                if (!acc.some(item => item.Listingkey === current.Listingkey)) {
                    acc.push(current);
                }
                return acc;
            }, []);
            setUnActiveProperties(uniqueListings)
        }
    }
    useEffect(() => {

        fetchProperties()
        fetchActiveProperties()
        fetchUnActiveProperties()
    }, [data])

    useEffect(() => {
        fetchData()
        fetchProperties()
        fetchActiveProperties()
        fetchUnActiveProperties()
    }, [])

    const [currentIndex, setCurrentIndex] = useState(0);
    const photos = data?.images && JSON.parse(data?.images) || [sec5logo];

    // Cycle through the photos
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [photos.length]);


    const allPhotos = properties && properties.length > 0 && properties.flatMap((property) => property.Photos || []);


    // Map to transform the photos array
    const transformedImages = allPhotos && allPhotos.length > 0 && allPhotos.map((photo) => ({
        original: photo.MediaURL,
        thumbnail: photo.MediaURL,
    }));
    const firstImage = photos && photos.length > 0 && photos.map((photo) => ({
        original: photo,
        thumbnail: photo,
    }));

    function transformToEmbedUrl(regularUrl) {
        // Extract the place ID or parameters from the original URL
        const match = regularUrl.match(/maps\/place\/([^/@]+)/);
        const place = match ? match[1] : null;
      
        if (place) {
          return `https://www.google.com/maps/embed?pb=!1m2!1m1!1s${encodeURIComponent(place)}`;
        }
      
        // Fallback if the URL does not match the expected format
        return "Invalid Google Maps URL";
      }

    const randomImages = transformedImages &&
        (transformedImages.length > 14
            ? transformedImages
                .slice(0, 14) // Pick first 14
            : transformedImages) || [];


    const handleCopyUrl = () => {
        const currentUrl = window.location.href; // Current URL fetch karna
        navigator.clipboard
            .writeText(currentUrl) // Clipboard mein URL copy karna
            .then(() => {
                message.success("URL copied successfully!"); // Success message show karna
            })
            .catch((error) => {
                message.error("Failed to copy URL."); // Error message show karna
                console.error("Copy failed", error);
            });
    };
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay for demonstration purposes
        const loadSite = async () => {
            await new Promise((resolve) => setTimeout(resolve, 4000)); // Simulate data fetching
            setIsLoading(false);
        };

        loadSite();
    }, []);

    let randomImageFullArray = [...firstImage, ...randomImages];


    function calculateAveragePricePerSqft(units) {
        const total = units.reduce(
          (acc, unit) => {
            acc.totalPrice += unit?.ListingInfo?.PriceInfo?.ClosePrice ?? unit?.ListingInfo?.PriceInfo?.OriginalListPrice ;
            acc.totalSqft += unit?.PropertyDetails?.LivingArea;
            return acc;
          },
          { totalPrice: 0, totalSqft: 0 }
        );
      
        // Avoid division by zero
        return total.totalSqft > 0 ? total.totalPrice / total.totalSqft : 0;
      }
      
      const prices = ActiveProperties.map((unit) => unit?.ListingInfo?.PriceInfo?.OriginalListPrice);

      
    return (
        <>
            <Helmet>
                <title>{`${data?.name || 'Building'} - Luxlife Miami Real Estate LLC`}</title>
            </Helmet>

            {isLoading && (
                <div className="fixed inset-0 bg-white bg-opacity-20 flex items-center justify-center z-50">
                    <Spin
                        size="large"
                        spinning={isLoading}
                        indicator={<LoadingOutlined spin />}
                    />
                </div>
            )}
            <div className="relative w-full min-h-[500px] md:h-[95vh] sm:h-[85vw] h-[85vw] overflow-hidden">
                <div className='fixed top-0 z-[10000] w-full'>
                    <Header />
                </div>
                {/* Image with full width and object cover to maintain aspect ratio */}
                <div className="relative w-full h-full">
                    {/* Slideshow images */}
                    {randomImageFullArray.map((photo, index) => {
                        const photoUrl = photo?.original;

                        return (
                            <img
                                key={index}
                                src={photoUrl}
                                alt={`Slide ${index}`}
                                className={`absolute w-full h-full object-cover transition-opacity duration-1000 ${index === currentIndex ? "opacity-100" : "opacity-0"
                                    }`}
                                style={{ filter: "blur(8px) brightness(80%)" }}
                            />
                        );
                    })}

                    {/* Foreground image */}
                    <img
                        src={randomImageFullArray[currentIndex]?.original
                        }
                        alt="Foreground Image"
                        className="relative w-full h-full"
                        style={{ objectFit: "contain" }}
                    />
                </div>


                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 gradient-background "></div>

                {/* Centered text block */}
                <div className="absolute bottom-[10%] md:left-6 text-white px-4 md:px-8 w-full">
                    <div>
                        {/* Animated Heading */}
                        <p className="text-[6vw] md:text-[4vw]">
                            {data?.name}
                            <TypeTextAnimation text={[]} speed={75} />
                        </p>
                        {/* Animated Address */}
                        <p className="text-[3vw] md:text-[2vw] lg:text-[1.2vw] creato-f-m tracking-wide mx-auto">
                            {data?.address}
                        </p>
                    </div>
                    <div className="flex justify-center mx-auto md:justify-end items-center mt-14 mr-[2%] gap-2 lg:gap-4">
                        {/* Buttons */}
                        {data?.floorplan && <a href={data?.floorplan} target='_blank'>
                            <Button
                                title={
                                    <div className="flex items-center gap-2">
                                        <span><LuDownload className="text-[16px] md:text-[20px]" /></span>
                                        <div>
                                            <p className="text-[9px]">
                                                <TypeTextAnimation text={['Download']} speed={75} />
                                            </p>
                                            <p className="text-[8px] lg:text-[12px]">
                                                <TypeTextAnimation text={['Floor Plans']} speed={75} />
                                            </p>
                                        </div>
                                    </div>
                                }
                                className={'bg-white px-2 lg:px-4 text-theme_blue rounded-xl py-3 creato-f-m tracking-wide btn2'}

                            />
                        </a>}

                        <div className="flex gap-2 lg:gap-4">
                            {/* Icons */}
                            <div className='absolute'>
                                {isPreviewVisible && <Image.PreviewGroup
                                    preview={{
                                        visible: isPreviewVisible,
                                        onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
                                    }}>
                                    {
                                        <>
                                            {Array.isArray(randomImageFullArray) && Array.length ? randomImageFullArray.map(src => <Image
                                                width={0}
                                                src={src?.original}
                                            />) : <></>}
                                        </>}
                                </Image.PreviewGroup>}
                            </div>
                            <div className=" cursor-pointer p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black" onClick={() => setPreviewVisible(!isPreviewVisible)}>
                                <AiFillPicture />
                            </div>
                            <a href={data.location ?? "#"} target='_blank'>
                                <div className="p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black  cursor-pointer">
                                    <FaLocationDot />
                                </div></a>
                            <div className="p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center bg-white text-black  cursor-pointer " onClick={handleCopyUrl}>
                                <IoIosShareAlt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" mx-auto relative sm:md:lg:rounded-t-[70px] rounded-t-[30px] bg-white top-[-68px] sm:md:lg:top-[-106px]">
                <div className=" container lg:max-w-[75%] mx-auto p-6 mt-10">
                    {/* Header with property details */}
                    <AnimationDiv
                        variants={slideBottom}
                        initial="hidden"
                        animate="visible"
                        className="flex flex-col justify-center items-center mb-6 pt-8 rounded-lg text-theme_blue"
                    >
                        <div className="bg-[#022541] sm:md:lg:max-w-[100vw] flex-wrap gap-[20px] sm:md:lg:gap-2 rounded-lg sm:md:lg:rounded-full px-8 sm:md:lg:px-24 py-3 flex justify-center">
                        {properties && properties.length > 0 && <a
                                href="#featured"
                                className={`text-lg my-1 text-center block text-white creato-f-r ${activeLink === "#featured" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Featured
                            </a>}
                            <a
                                href="#about"
                                className={`text-lg my-1 text-center  block text-white creato-f-r ${(properties && properties.length == 0 || activeLink === "#about") ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                About
                            </a>
                            <a
                                href="#gallery"
                                className={`text-lg my-1 text-center block text-white creato-f-r ${activeLink === "#gallery" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Gallery
                            </a>
                            <a
                                href="#description"
                                className={`text-lg text-center sm:md:lg:mx-3 px-2 my-1 block text-white creato-f-r ${activeLink === "#description" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Description
                            </a>
                            <a
                                href="#amenities"
                                className={`text-lg my-1 text-center block text-white creato-f-r ${activeLink === "#amenities" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Amenities
                            </a>
                            <a
                                href="#map"
                                className={`text-lg my-1 text-center block text-white creato-f-r ${activeLink === "#map" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Map
                            </a>
                           
                            <a
                                href="#listings"
                                className={`text-lg my-1 text-center block text-white creato-f-r ${activeLink === "#listings" ? "border-b-4 border-theme_yellow px-2 sm:md:lg:mx-3" : "sm:md:lg:mx-5"
                                    }`}
                            >
                                Listings
                            </a>
                        </div>
                    </AnimationDiv>
                </div>




                <div className={`flex items-start lg:justify-between flex-col lg:flex-row mx-auto md:mt-[-4%] w-full rounded-t-[70px] bg-white p-4 md:p-12 ${window.scrollY >= 4450 ? 'items-end': 'items-start'}`}>

                    <div className="md:px-20 container">

                        {properties && properties.length > 0 && <div id='featured'>

                            <p className="creato-f-b text-[2rem]  mt-[35px]">Featured Condos for Sale at {newName}</p>
                            <div className="flex flex-wrap gap-4 justify-center mt-[15px]">
                                {properties.slice(0, 6).map((data) => (
                                    <Card
                                        key={data.id}
                                        data={data}
                                        toggleWishlist={toggleWishlist}
                                        className="shadow-xl min-w-[300px] sm:md:lg:w-[400px]"
                                    />
                                ))}
                            </div>


                            {properties && properties.length > 6 && <div className='flex justify-center mt-14'>
                                <Button onClick={() => navigate('/miami/on-filter', { state: { filter: `BuildingName.in=${newName}` } })} title={<div className='flex items-center gap-4'><span>View more Condos</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_blue px-8 text-theme_yellow rounded-full py-3 creato-f-m  tracking-wide btn2'} />
                            </div>}

                        </div>}

                        <div id='about'>
                            
                                                        <p className="creato-f-b text-[2rem]  mt-[3em]">About {newName}</p>

                            {data?.developer && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue mt-6 gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">DEVELOPER</h2>
                                </div>
                                <p className="creato-f-m text-xl">{data?.developer}</p>
                            </div>}
                            {data?.totalunits && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">TOTAL UNITS</h2>
                                </div>
                                <p className="creato-f-m text-xl">{data?.totalunits}</p>
                            </div>}
                           {data?.architect && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">ARCHITECT</h2>
                                </div>
                                <p className="creato-f-m text-xl">{data?.architect}</p>
                            </div>}
                            {data?.yearbuilt && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">YEAR BUILT</h2>
                                </div>
                                <p className="creato-f-m text-xl">{data?.yearbuilt}</p>
                            </div>}
                           {data?.unit_size && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">UNIT SIZE RANGE</h2>
                                </div>
                                <p className="creato-f-m text-xl">{data?.unit_size}</p>
                            </div>}
                            {(ActiveProperties.length > 0) && <div className="flex md:text-[1.1vw] py-6 border-b border-theme_blue gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">TODAY'S PRICE ({new Date().toLocaleDateString()})</h2>
                                </div>
                                <p className="creato-f-m text-xl">From ${Math.min(...prices).toLocaleString()} to ${Math.max(...prices).toLocaleString()}</p>
                            </div>}
                            {ActiveProperties && <div className="flex md:text-[1.1vw] py-6 gap-16">
                                <div className="">
                                    <h2 className="creato-f-l text-xl">UNITS FOR SALE</h2>
                                </div>
                                <p className="creato-f-m text-xl">{ActiveProperties.length}</p>
                            </div>}

                        </div>



                        <div id='gallery'>                        
                                <p className="creato-f-b text-[2rem]  mt-[3em]">Images of {newName}</p>

                            <div className="image-gallery-wrapper mt-6">
                                <ImageGallery
                                    items={randomImageFullArray}
                                    autoPlay={false}
                                    showThumbnails={true}
                                    useBrowserFullscreen={false}
                                    showPlayButton={false}
                                    showBullets={false}
                                    showIndex={false}
                                    infinite={true}
                                    lazyLoad={true}
                                    slideInterval={5000}
                                    slideDuration={500}

                                    thumbnailPosition={"right"}
                                />
                            </div>
                        </div>
                        {/* Main content */}
                        {data?.description && <div id='description'>
                            <p className="creato-f-b text-[2rem]  mt-[3em]">Description of {newName}</p>



                            <AnimationDiv variants={slideLeft}
                                initial="hidden"
                                animate="visible" className="creato-f-m mb-8 md:text-lg">
                                <p className="text-justify newBuilding" dangerouslySetInnerHTML={{ __html: data?.description }} />



                            </AnimationDiv>
                        </div>}

                        {data && data?.amenities && JSON.parse(data?.amenities).length > 0 && <div id='amenities'>
                            <p className="creato-f-b text-[2rem]  mt-[3em]">Amenities of {newName}</p>

                            <div className='mt-8' style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent:"flex-start" }}>
      {data && data?.amenities && JSON.parse(data?.amenities).length > 0 && JSON.parse(data?.amenities).map((amenity, index) => (
        <div key={index} className='mx-3' style={{ textAlign: "center", display:'flex', justifyContent:'center', flexDirection:'column', width:'100px' }}>
          <img
            src={amenityImages[amenity.toLocaleLowerCase().trim()] ?? LuxlifeAmmenities}
            alt={amenity}
            style={{ width: "100px", height: "auto", borderRadius: "10px" }}
          />
          <p className='creato-f-m' style={{ marginTop: "10px" }}>{amenity}</p>
        </div>
      ))}
    </div>

                        </div>}


                        <div id='map'>                        <p className="creato-f-b text-[2rem]  mt-[3em]">Map of {newName}</p>
                        <div className='mt-6'><iframe width={"100%"} height={"600vh"} src={`https://www.google.com/maps/embed/v1/place?q=${newName}&key=AIzaSyDIhdl8K0VsF3UJ7nYsT5ttYyU22konJ9c`} /></div>
</div>


<div id='listings'>                        <p className="creato-f-b text-[2rem]  mt-[3em]">Listings of {newName}</p>
                        <p className='text-lg creato-f-m mt-2'>{ActiveProperties.length} Units for Sale</p>
                       {ActiveProperties && ActiveProperties.length > 0 && <p className='text-lg creato-f-l mt-1'>Average listing price for all ACTIVE units: ${calculateAveragePricePerSqft(ActiveProperties).toFixed(2)}/SqFt
                        </p>}
                       {UnActiveProperties && UnActiveProperties.length > 0 && <p className='text-lg creato-f-l mt-1 mb-4'>Average listing price for all CLOSED units: ${calculateAveragePricePerSqft(UnActiveProperties).toFixed(2)}/SqFt
                        </p>}
</div>








                    </div>


                    <div id="agent-card" className={`min-w-[350px] text-center flex flex-col justify-center items-center pt-0 px-4 relative mt-0 md:mt-16 `}>
<div className={`${isStuck ? 'sm:md:lg:xl:fixed sm:md:lg:xl:top-10 sm:md:lg:xl:mt-[10%] sm:md:lg:xl:right-10 sm:md:lg:xl:bottom-10' : 'relative'} `}>
                        <img className='max-w-[200px]' src={data?.logo} />
                        <AgentCard />
                    </div>
</div>

                </div>
                {ActiveProperties && ActiveProperties
                    .filter(item => item?.ListingInfo?.MLSDetails?.StandardStatus === "Active").length > 0 && <div className='bg-[#FEE8E8] mt-10'>
                        <div className='lg:max-w-[75%] px-4 py-10 mx-auto'>
                            {ActiveProperties && ActiveProperties
                                .filter(item => item?.ListingInfo?.MLSDetails?.StandardStatus === "Active").length > 0 && <ResidenceTable title="Available Residences" data={ActiveProperties
                                    .filter(item => item?.ListingInfo?.MLSDetails?.StandardStatus === "Active")} />}
                        </div>
                        <div className='lg:max-w-[75%] px-4 pb-10 mx-auto'>
                            {UnActiveProperties && UnActiveProperties
                                .filter(item => item?.ListingInfo?.MLSDetails?.StandardStatus !== "Active").length > 0 && <ResidenceTable title="Sold or Leased Residences" data={UnActiveProperties
                                    .filter(item => item?.ListingInfo?.MLSDetails?.StandardStatus !== "Active")} />}

                        </div>
                    </div>}
<div className='text-center w-100 flex flex-col justify-center items-center'>

                    <p className='mt-24 creato-f-m text-sm max-w-[1200px]'>The available condo data in this report is limited to properties for sale at $1M and higher. Less expensive properties may not be appearing on this site.</p>
                    <p className='mt-4 creato-f-l text-xs max-w-[1200px]'>TThe properties above are provided by Miami Association of Realtors. Data updated {new Date().toLocaleDateString()}. Daniel Pansky is licensed with Luxlife Miami Real Estate LLC as a cooperating broker or cooperating agent. The information above is believed to be accurate, but is not guaranteed. The data for these properties comes from the Internet Data Exchange (IDX), a collaboration between brokers and REALTOR® associations in which brokers allow their listings to be syndicated to websites hosted by cooperating REALTOR® member brokers. Brokers appearing on this website as the contact person for any property is not necessarily the listing agent. The information being provided is for consumers' personal, non-commercial use. Federal law prohibits discrimination on the basis of race, color, religion, sex, handicap, familial status or national origin in the sale, rental or financing of housing.</p>
</div>
            </div >
            <section>
                <ContactUs className="mt-0" />
            </section>
            <Footer />
        </>
    );
};

export default SingleBuilding;
