import React, { useEffect, useState } from 'react'
import Hero from '../components/ui/sections/HeroSection/Hero'
import Section2 from '../components/ui/sections/section_2/Section2'
import Section3 from '../components/ui/sections/section_3/Section3'
import Section4 from '../components/ui/sections/section_4/Section4'
import Section5 from '../components/ui/sections/section5/Section5'
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs'
import ChatWidget from '../components/chat/ChatBox'
import MeetExpertSec from '../components/ui/sections/meet_expert_section/MeetExpertSec'
import Footer from '../components/ui/sections/FooterSection/Footer'
import { getExclusiveListingData, getListingData } from '../api/GET'
import axios from 'axios'
import { API_BASE_URL } from '../config'
import { Helmet } from 'react-helmet'
import FooterDirectory from './FooterDirectory'

const Home = () => {
    const [properties, setProperties] = useState([])
    const [filterQuery, setfilterQuery] = useState('OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell')
    const [loading, setLoading] = useState(false)
    const fetchProperties = async () => {
        setLoading(true)
        const data = await getExclusiveListingData(`&sortBy=OriginalListPrice&limit=15&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&order=desc&${filterQuery}`);
        if (Array.isArray(data?.data?.data)) {
            const uniqueListings = data?.data?.data.reduce((acc, current) => {
                if (!acc.some(item => item.Listingkey === current.Listingkey)) {
                  acc.push(current);
                }
                return acc;
              }, []);
            setProperties(uniqueListings)
        }
        setLoading(false)
    }
    useEffect(() => {

        fetchProperties()
    }, [filterQuery])


    const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
        if (loading) return;
    
        setLoading(true);
    
        const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
        const headers = {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        };
    
        try {
            if (!isSaved) {
                setIsSaved(true); // Mark as saved
                // API call to add to saved
                await axios.post(
                    `${API_BASE_URL}domainparmeter/saved-property/store`,
                    { listing_id: ListingID },
                    { headers } // Pass headers with the request
                );
            } else {
                setIsSaved(false); // Mark as unsaved
                // API call to remove from saved
                await axios.delete(
                    `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
                    { headers } // Pass headers with the request
                );
            }
        } catch (error) {
            console.error('Error toggling wishlist:', error);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="">
            <Helmet>
                    <title>Luxlife Miami Real Estate LLC</title>
                </Helmet>
            <Hero />
            <Section2 />
            <Section3 />
            <Section4 setfilterQuery={setfilterQuery} />
            {/* <MeetExpertSec /> */}
            <Section5 propertyData={properties} loading={loading} toggleWishlist={toggleWishlist} />
            <FooterDirectory />
            <ContactUs />
            {/* <ChatWidget /> */}
            <Footer />
        </div>
    )
}

export default Home