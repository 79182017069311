import React, { useEffect, useRef, useState } from 'react';
import ContactLayout from '../components/ui/layout/ContactLayout';
import ListingsUI from '../components/tabs/TabNew100listing';
import { Badge, Empty, Spin, Table } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useData } from '../context/DataContext';
import { getAllCities, getListingCondosData, getListingPropertyData, getListingExpensiveData, getListingHomeData, getListingVillaData } from '../api/GET';
import { LoadingOutlined } from '@ant-design/icons';
import { isLoginHandle } from '../lib/helper';
import { API_BASE_URL } from '../config';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { IoIosAdd } from 'react-icons/io';
import Filter from '../components/ui/filter/Filter';


export const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const tabs = [
  { tab: 'listing', label: '100 Newest Listings', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
  { tab: 'penthouses', label: '100 Newest Penthouses', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
  { tab: 'condos', label: '100 Newest Condos', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
  { tab: 'homes-100', label: '100 Newest Homes', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
  { tab: 'expensive', label: '100 Most Expensive', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
];







const ScrollGrid = ({ listingData }) => {

  const { setKeyData } = useData()

  const handleCardClick = () => {
    if (!isLoginHandle()) {
      setKeyData('isLoginModalOpen', true);
      return;
    }
  }
  const columns = [
    {
      title: "Property",
      dataIndex: "Property",
      key: "Property",
      sorter: (a, b) =>
        (a?.ListingInfo?.StreetAddress?.Address.split(",")[0] || "").localeCompare(
          b?.ListingInfo?.StreetAddress?.Address.split(",")[0] || ""
        ),
      align: "center",
      render: (text, record) => (
        <Link className='w-full hover:text-white' target="_blank"
          to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
          onClick={handleCardClick}
        >

          <div>
            <p className="creato-f-l items-start text-left">
              {record?.ListingInfo?.StreetAddress?.Address
                ? record?.ListingInfo?.StreetAddress?.Address.split(",")[0] || ""
                : "Property not Found"}
            </p>
          </div>
        </Link>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => (a.City || "").localeCompare(b.City || ""),
      align: "center",
      render: (text, record) => (
        <Link className='w-full hover:text-white' target="_blank"
          to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
          onClick={handleCardClick}
        >

          <div>
            <p className="creato-f-l justify-center items-center text-center">
              {record?.City ? record?.City || "" : "City not Found"}
            </p>
          </div>
        </Link>
      ),
    },
    {
      title: "List Price",
      dataIndex: "list_price",
      key: "list_price",
      sorter: (a, b) =>
        (a?.ListingInfo?.PriceInfo?.ClosePrice || a?.ListingInfo?.PriceInfo?.ListPrice || 0) -
        (b?.ListingInfo?.PriceInfo?.ClosePrice || b?.ListingInfo?.PriceInfo?.ListPrice || 0),
      align: "center",
      render: (text, record) => (
        <Link className='w-full hover:text-white' target="_blank"
          to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
          onClick={handleCardClick}
        >

          <div>
            <p className="creato-f-l justify-center items-center text-center">
              $
              {record?.ListingInfo?.PriceInfo?.ClosePrice
                ? record?.ListingInfo?.PriceInfo?.ClosePrice.toLocaleString()
                : record?.ListingInfo?.PriceInfo?.ListPrice
                  ? record?.ListingInfo?.PriceInfo?.ListPrice.toLocaleString()
                  : "List Price not Found"}
            </p>
          </div>
        </Link>
      ),
    },
    {
      title: "List $/ SqFt.",
      dataIndex: "list_price_sqft",
      key: "list_price_sqft",
      sorter: (a, b) => {
        const priceA =
          a?.ListingInfo?.PriceInfo?.ClosePrice || a?.ListingInfo?.PriceInfo?.ListPrice || 0;
        const priceB =
          b?.ListingInfo?.PriceInfo?.ClosePrice || b?.ListingInfo?.PriceInfo?.ListPrice || 0;
        const sqftA = Number(a?.PropertyDetails?.LivingArea) || 0;
        const sqftB = Number(b?.PropertyDetails?.LivingArea) || 0;
        return sqftA && sqftB ? priceA / sqftA - priceB / sqftB : 0;
      },
      align: "center",
      render: (text, record) => {
        const price =
          record?.ListingInfo?.PriceInfo?.ClosePrice ||
          record?.ListingInfo?.PriceInfo?.ListPrice ||
          0;
        const sqft = Number(record?.PropertyDetails?.LivingArea) || 0;
        const value = sqft ? (price / sqft).toFixed(0) : "∞";
        return (
          <Link className='w-full hover:text-white' target="_blank"
            to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
            onClick={handleCardClick}
          >

            <div>
              <p className="creato-f-l justify-center items-center text-center">
                ${value === "∞" ? value : Number(value).toLocaleString()}
              </p>
            </div>
          </Link>
        );
      },
    },
    {
      title: "Beds/Bath",
      dataIndex: "bed_bath",
      key: "bed_bath",
      sorter: (a, b) =>
        (a?.PropertyDetails?.Bedrooms || 0) -
        (b?.PropertyDetails?.Bedrooms || 0) ||
        (a?.PropertyDetails?.BathroomsTotal || 0) -
        (b?.PropertyDetails?.BathroomsTotal || 0),
      align: "center",
      render: (text, record) => {
        const beds = Math.floor(record?.PropertyDetails?.Bedrooms || 0);
        const baths = record?.PropertyDetails?.BathroomsTotal || 0;
        const totalBaths = Math.floor(baths);
        const halfBath = baths % 1 !== 0 ? "½" : "";
        return (
          <Link className='w-full hover:text-white' target="_blank"
            to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
            onClick={handleCardClick}
          >

            <div>
              <p className="creato-f-l justify-center items-center text-center">
                {beds && baths ? `${beds}/${totalBaths}${halfBath}` : "Beds/Bath not Found"}
              </p>
            </div>
          </Link>
        );
      },
    },
    {
      title: "SqFt (㎡)",
      dataIndex: "sqft",
      key: "sqft",
      sorter: (a, b) => (a?.PropertyDetails?.LivingArea || 0) - (b?.PropertyDetails?.LivingArea || 0),
      align: "center",
      render: (text, record) => {
        const sqft = record?.PropertyDetails?.LivingArea || 0;
        return (
          <Link className='w-full hover:text-white' target="_blank"
            to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
            onClick={handleCardClick}
          >

            <div>
              <p className="creato-f-l justify-center items-center text-center">
                {sqft
                  ? `${sqft} SqFt (${(sqft * 0.09290304).toFixed(2)} ㎡)`
                  : "SqFt (㎡) not Found"}
              </p>
            </div>
          </Link>
        );
      },
    },
    {
      title: "Year Built",
      dataIndex: "year_built",
      key: "year_built",
      sorter: (a, b) =>
        (a?.PropertyDetails?.YearBuilt || 0) - (b?.PropertyDetails?.YearBuilt || 0),
      align: "center",
      render: (text, record) => (
        <Link className='w-full hover:text-white' target="_blank"
          to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
          onClick={handleCardClick}
        >

          <div>
            <p className="creato-f-l justify-center items-center text-center">
              {record?.PropertyDetails?.YearBuilt || "Year Built not Found"}
            </p>
          </div>
        </Link>
      ),
    },
    {
      title: "Listing Date",
      dataIndex: "listing_date",
      key: "listing_date",
      sorter: (a, b) =>
        new Date(
          a?.ListingInfo?.MLSDetails?.CloseDate ||
          a?.ListingInfo?.MLSDetails?.ListingContractDate ||
          "1970-01-01"
        ) -
        new Date(
          b?.ListingInfo?.MLSDetails?.CloseDate ||
          b?.ListingInfo?.MLSDetails?.ListingContractDate ||
          "1970-01-01"
        ),
      align: "center",
      render: (text, record) => {
        const date =
          record?.ListingInfo?.MLSDetails?.CloseDate ||
          record?.ListingInfo?.MLSDetails?.ListingContractDate ||
          null;
        const formattedDate = date
          ? new Intl.DateTimeFormat("en-US").format(new Date(date))
          : "Listing Date not Found";
        return (
          <Link className='w-full hover:text-white' target="_blank"
            to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
            onClick={handleCardClick}
          >

            <div>
              <p className="creato-f-l justify-center items-center text-center">{formattedDate}</p>
            </div>
          </Link>
        );
      },
    },
    {
      title: "Status",
      key: "property_status",
      sorter: (a, b) =>
        (a?.ListingInfo?.MLSDetails?.StandardStatus || "").localeCompare(
          b?.ListingInfo?.MLSDetails?.StandardStatus || ""
        ),
      align: "center",
      render: (text, record) => (
        <Link className='w-full hover:text-white' target="_blank"
          to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
          onClick={handleCardClick}
        >

          <div className="justify-center items-center text-center">
            <p className="creato-f-l justify-center items-center text-center">{record?.ListingInfo?.MLSDetails?.StandardStatus}</p>
          </div>
        </Link>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <div className="justify-center items-center text-center">
          <Link className='w-full hover:text-white' target="_blank"
            to={isLoginHandle() ? `/single-property/${record?.ListingID}` : "#"}
            onClick={handleCardClick}
          >
            <IoIosAdd size={20} />
          </Link>
        </div>
      ),
    },
  ];

  return Array.isArray(listingData?.data) && listingData?.data?.length ? (
    <>
      <Table
        className='creato-f-l justify-center items-center text-center custom-ant-table'
        rowClassName={() => "custom-row bg-white"}
        style={{ overflowX: "auto", background: 'white', }}
        pagination={false}
        bordered
        dataSource={listingData?.data}
        columns={columns}
      />
    </>
  ) : (
    <div className="flex justify-center col-span-3">
      <Empty className="mx-auto" image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );

};





const New100Listing = () => {
  const { tab } = useParams();
  const location = useLocation()
  const { data } = useData()
  // console.log('tabs.find(v => v.tab === `/new-100/${tab}`) ?? tabs[0]?.tab',tabs.find(v => v?.tab === tab));

  const [activeTab, setActiveTab] = useState(tabs.find(v => v.tab === tab) ?? tabs[0]);
  const [areas, setAreas] = useState([]);
  const [listingData, setlistingData] = useState([])
  const [priceRange, setPriceRange] = useState([1000000, 5000001]);
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [filterQuery, setFilterQuery] = useState('')
  useEffect(() => {
    // if (Array.isArray(data?.propertyType) && data?.propertyType?.length) {
    setActiveTab(tabs.find(v => v.tab === tab) ?? tabs[0])
    if(tab === 'penthouses' && priceRange[0] <= 3000000){
      setPriceRange([3000000, 5000001])
    }
    else{
      setPriceRange([1000000, 5000001])
    }
    
  }, [tab])
  const fetchCities = async () => {
    const city_data = await getAllCities();

    if (Array.isArray(city_data?.data?.uniqueCities)) {
      const selectedArea = city_data.data?.uniqueCities.map(v => ({ name: v, selected: (Array.isArray(location.state) && location.state.includes(v)) ? true : false }))
      setAreas(selectedArea)

      setLoading(true);

      const query = {
        offset: 0,
        limit: 100,
        'OriginalListPrice.gte': (tab === 'penthouses' && priceRange[0] >= 3000000) ? priceRange[0] : (tab !== 'penthouses') ? priceRange[0] : 3000000,
        ...(priceRange[1] !== 5000001 && { 'OriginalListPrice.lte': priceRange[1] })
      };

      // Add UnparsedAddress.in parameters based on selected areas
      const searchParams = new URLSearchParams(query);
      if (!location.state) {
        if (areas && areas.length !== 0) {
          areas
            .filter(v => v.selected)
            .forEach(v => searchParams.append("UnparsedAddress.in", v.name));
        }
      } else {
        console.log(selectedArea);

        if (selectedArea && selectedArea.length !== 0) {
          selectedArea
            .filter(v => v.selected)
            .forEach(v => searchParams.append("UnparsedAddress.in", v.name));
        }
      }

      const data = tab === 'expensive'
        ? await getListingExpensiveData(searchParams.toString() + "&" + filterQuery)
        : tab === 'homes-100' ? await getListingHomeData(searchParams.toString() + "&" + filterQuery)
          : tab === 'condos' ? await getListingCondosData(searchParams.toString() + "&" + filterQuery)
            : tab === 'penthouses' ? await getListingVillaData(searchParams.toString() + "&" + filterQuery)
              : await getListingPropertyData(searchParams.toString() + "&" + filterQuery);

      // Set listing data if available
      if (data?.data) {
        setlistingData(data.data);
      }
      setLoading(false);
    }
    window.history.replaceState({}, '')

  }
  useEffect(() => {
    fetchCities()
  }, [])

  const fetchListing = async (filterQuery) => {
    setLoading(true);

    const query = {
      offset: 0,
      limit: 100,
      ...({ 'OriginalListPrice.gte': (tab === 'penthouses' && priceRange[0] >= 3000000) ? priceRange[0] : (tab !== 'penthouses') ? priceRange[0] : 3000000 }),
      ...(priceRange[1] !== 5000001 && { 'OriginalListPrice.lte': priceRange[1] })
    };

    // Add UnparsedAddress.in parameters based on selected areas
    const searchParams = new URLSearchParams(query);
    if (areas && areas.length !== 0) {
      areas
        .filter(v => v.selected)
        .forEach(v => searchParams.append("UnparsedAddress.in", v.name));
    }

    const data = tab === 'expensive'
      ? await getListingExpensiveData(searchParams.toString() + "&" + filterQuery)
      : tab === 'homes-100' ? await getListingHomeData(searchParams.toString() + "&" + filterQuery)
        : tab === 'condos' ? await getListingCondosData(searchParams.toString() + "&" + filterQuery)
          : tab === 'penthouses' ? await getListingVillaData(searchParams.toString() + "&" + filterQuery)
            : await getListingPropertyData(searchParams.toString() + "&" + filterQuery);

    // Set listing data if available
    if (data?.data) {
      setlistingData(data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true)

    fetchCities()
  }, [tab])
  useEffect(() => {
    setLoading(true)

    if (filterQuery) {
      fetchListing(filterQuery)
    }
  }, [filterQuery])

  const handleSearchFilter = () => {
    console.log('search click')
    setLoading(true)
    fetchListing(filterQuery)
  }
  const handleScrollEnd = async () => {
    setLoadMore(true)
    // Your function to call when scroll ends within the card container
    console.log('Container scroll ended, loading more cards...', listingData);
    // Add your logic here
    const currendData = { ...listingData };

    if (currendData?.has_more) {
      const query = {
        offset: currendData.next_offset,
        limit: 15,
        PropertySubType: tab ? tab?.replaceAll('-', ' ') : "",
        City: (areas && areas.length !== 0 ? areas.map(v => v.selected ? v.name : null).filter(Boolean)[0] : "") ?? ''
      }
      const searchParams = new URLSearchParams(query).toString() + "&" + filterQuery;


      const data = await getListingPropertyData(searchParams);

      if ((data?.data)) {
        const newData = {
          ...data.data,
          data: [...listingData.data, ...data.data?.data]
        }
        setlistingData(newData)
      }

    }
    setLoadMore(false)
  };


  const toggleWishlist = async (loading, setLoading, isSaved, setIsSaved, ListingID) => {
    if (loading) return;

    setLoading(true);

    const token = JSON.parse(localStorage.getItem('user_data'))?.token; // Retrieve the token from localStorage
    const headers = {
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    };

    try {
      if (!isSaved) {
        setIsSaved(true); // Mark as saved
        // API call to add to saved
        await axios.post(
          `${API_BASE_URL}domainparmeter/saved-property/store`,
          { listing_id: ListingID },
          { headers } // Pass headers with the request
        );
      } else {
        setIsSaved(false); // Mark as unsaved
        // API call to remove from saved
        await axios.delete(
          `${API_BASE_URL}domainparmeter/saved-property/${ListingID}`,
          { headers } // Pass headers with the request
        );
      }
    } catch (error) {
      console.error('Error toggling wishlist:', error);
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for demonstration purposes
    const loadSite = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate data fetching
      setIsLoading(false);
    };

    loadSite();
  }, []);
  return (
    <ContactLayout>
      <Helmet>
        <title>{`${(tab === 'listing') ? 'The 100 Newest Real Estate Listings in Miami' : (tab === 'penthouses') ? 'Luxlife list of 100 Penthouses' : (tab === 'condos') ? 'Luxlife list of 100 Condos' : (tab === 'homes-100') ? 'Luxlife list of 100 Homes' : 'Luxlife list of 100 Expensive Homes'} - Luxlife Miami Real Estate LLC`}</title>
      </Helmet>
      {isLoading && (
  <div className="fixed inset-0 bg-white bg-opacity-20 flex items-center justify-center z-50">
    <Spin
      size="large"
      spinning={isLoading}
      indicator={<LoadingOutlined spin />}
    />
  </div>
)}

      <div className='p-4  py-[20px] lg:pl-[70px] lg:pr-[48px] bg-theme_yellow flex gap-10 justify-center'>
        <Filter setfitlerQuery={setFilterQuery} hidePrice={true} />
      </div>
      <div>
        <h2 className='text-[32px] md:text-[3vw] creato-f-b text-center tracking-wide text-theme_blue mt-[75px]'>Luxlife's Top 100</h2>
      </div>
      <ListingsUI paramsTab={tab} activeTab={activeTab} setActiveTab={setActiveTab} priceRange={priceRange} setPriceRange={setPriceRange} tabs={tabs} areas={areas} setAreas={setAreas} handleSearchFilter={handleSearchFilter} />
      <div className='px-[2%] '>
        {tab !== 'map-filter' && <div className='p-4 mt-14'>
          <h1 className='text-theme_blue md:text-[45px] creato-f-b'>{activeTab?.label}</h1>

        </div>}
        <div className='rounded-lg p-6 lg:p-10 text-gray-800 lg:mx-[5%]'>

          <Spin size="large" spinning={loading} indicator={<LoadingOutlined spin />}>
            <ScrollGrid
              toggleWishlist={toggleWishlist}
              listingData={listingData}
              onScrollEnd={handleScrollEnd}
              paramsTab={tab}
            />
            <Spin size="large" spinning={loadMore} indicator={<LoadingOutlined spin />}>
              {loadMore ? <div className='min-h-[100px] w-full'> </div> : <></>}

            </Spin>
          </Spin>
        </div>
        <div className='max-w-[1400px] segoeui text-[14px] leading-[19px] text-theme_grey2 text-center mx-auto py-10 px-4'>
          The properties above are provided by Miami Association of Realtors, and represent properties found in Aventura, Miami Beach, Sunny Isles Beach, Bal Harbour, Surfside, Miami, Key Biscayne. Data updated 8/5/2024. Daniel Pansky is licensed with Luxlife as a cooperating broker or cooperating agent. The information above believed to be accurate, but is not guaranteed. The data for these properties come from the Internet Data Exchange (IDX), a collaboration between brokers and REALTOR® associations in which brokers allow their listings to be syndicated to websites hosted by cooperating REALTOR® member brokers. Brokers appearing on this website as the contact person for any property is not necessarily the listing agent. The information being provided is for consumers' personal, non-commercial use. Federal law prohibits discrimination on the basis of race, color, religion, sex, handicap, familial status or national origin in the sale, rental or financing of housing.
        </div>
      </div>
    </ContactLayout>
  )
}

export default New100Listing